import request from '@/utils/request'

const oaRoleCategoryApi = {
  oaRoleCategoryList: '/oa/role_category/',
  oaRoleCategoryCreate: '/oa/role_category/',
  oaRoleCategoryUpdate: '/oa/role_category/',
  oaRoleCategoryDelete: '/oa/role_category/'
}

/**
 * 列表
 */
export function oaRoleCategoryList (parameter) {
  return request({
    url: oaRoleCategoryApi.oaRoleCategoryList,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function oaRoleCategoryCreate (parameter) {
  return request({
    url: oaRoleCategoryApi.oaRoleCategoryCreate,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function oaRoleCategoryUpdate (parameter, oa_role_category_id) {
  return request({
    url: oaRoleCategoryApi.oaRoleCategoryUpdate + oa_role_category_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function oaRoleCategoryDelete (oa_role_category_id) {
  return request({
    url: oaRoleCategoryApi.oaRoleCategoryDelete + oa_role_category_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
