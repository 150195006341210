<template>
  <div>
    <a-form :form="form">
      <a-form-item label="分类名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input
          v-decorator="['name', {rules: [{required: true, message: '请输入'}, {max:64, message: '输入不符合长度限制'}]}]"
          placeholder="分类名称"/>
      </a-form-item>
      <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-textarea
          v-decorator="['remark', {rules: [{required: false, message: '请输入'}, {max:128, message: '输入不符合长度限制'}]}]"
          :rows="2"
          placeholder="备注"/>
      </a-form-item>
      <a-form-item
        v-bind="buttonCol"
      >
        <a-row>
          <a-col span="6">
            <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
          </a-col>
          <a-col span="10">
            <a-button @click="handleGoBack">返回</a-button>
          </a-col>
          <a-col span="8"></a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import pick from 'lodash.pick'
import { oaRoleCategoryUpdate } from '@/api/oa_role_category'

export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.loadEditInfo(this.record)
    })
  },
  methods: {
    handleGoBack () {
      this.$emit('onGoBack')
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        if (!err) {
          oaRoleCategoryUpdate(values, this.id)
            .then((res) => {
              if (res.code === 1000) {
                this.handleGoBack()
              }
            })
        }
      })
    },
    loadEditInfo (data) {
      const { form } = this
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, ['id', 'name', 'remark'])
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
